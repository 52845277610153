import {observable} from "mobx";

const WS = observable({
  width: window.innerWidth,
  height: window.innerHeight,
  xs: window.innerWidth < 450,
  md: window.innerWidth >= 450 && window.innerWidth <= 900,
  d: window.innerWidth > 900 && window.innerWidth <= 1200,
  xl: window.innerWidth > 1200,
})

function handleResize() {
  // Set window width/height to state
  WS.width = window.innerWidth;
  WS.height = window.innerHeight;
  WS.xs = window.innerWidth < 450;
  WS.md = window.innerWidth >= 450 && window.innerWidth <= 900;
  WS.d = window.innerWidth > 900 && window.innerWidth <= 1200;
  WS.xl = window.innerWidth > 1200;
}

window.addEventListener("resize", handleResize);

export default WS;
